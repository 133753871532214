<template>
  <div>
    <b-row>
      <b-col cols="3">
        <AddressForm
          ref="addressForm"
          v-bind:value="address"
          v-on:input="updateAddress"
          v-on:delete="deleteAddress"
        />
      </b-col>

      <b-col cols="9">
        <Map
          ref="map"
          v-bind:highlight="location"
          v-on:click="mapClicked"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@/core/services/api";

import AddressForm from "@/view/pages/addresses/AddressForm.vue";
import Map from "@/view/pages/addresses/Map.vue";

const emptyAddress = {
  zone: "",
  street: "",
  streetNo: "",
  buildingNo: "",
  blockNo: "",
  landmark: "",
};

export default {
  name: "Addresses",

  components: {
    AddressForm,
    Map,
  },

  data() {
    return {
      address: { ...emptyAddress },

      location: null,
    };
  },

  mounted() {
    this.$store.dispatch("setBreadcrumb", [{ title: "Adrese" }]);
  },

  methods: {
    async mapClicked(location) {
      if (!location) return;

      const address = await api.addresses.create(location.lat, location.lng);

      this.address  = { ...emptyAddress, ...address };
      this.location = location;

      this.$refs.map.addAddress(address);
    },

    async updateAddress(address) {
      this.address = { ...address };

      await api.addresses.update(this.address.geohash, address);
    },

    async deleteAddress() {
      if (this.address.geohash) {
        await api.addresses.delete(this.address.geohash);
      }

      this.$refs.map.removeAddress(this.address);

      this.address  = { ...emptyAddress };
      this.location = null;
    },
  },
};
</script>
