<template>
  <Map
    ref="map"
    id="addresses-map"
    v-bind:controls="controls"
    v-on:mounted="mapMounted"
    v-on:click="mapClicked"
    v-on:move="mapMoved"
  >
    <vl-layer-vector>
      <vl-source-vector>
        <vl-feature
          v-for="address in addressEntries"
          v-bind:key="address.geohash"
          v-bind:id="address.geohash"
        >
          <vl-geom-polygon v-bind:coordinates="address.extent" />
          <vl-style-box>
            <vl-style-fill color="white" />
            <vl-style-stroke color="red" v-if="address.source === 'poi'" />
            <vl-style-stroke color="blue" v-else />
          </vl-style-box>
        </vl-feature>
      </vl-source-vector>
    </vl-layer-vector>

    <vl-feature
      v-for="address in addressEntries"
      v-bind:key="address.geohash"
      v-bind:id="address.geohash"
      v-bind:properties="{}"
    >
      <vl-geom-point v-bind:coordinates="address.coords" />
      <vl-style-box>
        <vl-style-circle v-bind:radius="3">
          <vl-style-fill color="white" />
          <vl-style-stroke color="cyan" v-if="address.source === 'poi'" />
          <vl-style-stroke color="red" v-else />
        </vl-style-circle>
      </vl-style-box>
    </vl-feature>

    <vl-feature v-if="highlightCoords">
      <vl-geom-point v-bind:coordinates="highlightCoords" />
      <vl-style-box>
        <vl-style-icon src="media/markers/place.png" v-bind:anchor="[0.5, 1]" />
      </vl-style-box>
    </vl-feature>
  </Map>
</template>

<script>
import jQuery from "jquery";
import lodash from "lodash";

import * as Polygon from "ol/geom/Polygon";
import { fromLonLat, toLonLat } from "ol/proj";
import * as Geohash from "ol-ext/geom/geohash";

import api from "@/core/services/api";

import { default as Map, projections, wkt } from "@/view/content/widgets/map/MapBase.vue";
import ButtonControl from "@/view/content/widgets/map/ButtonControl.js";

function mapAddress(address) {
  const lonLat = [address.lng, address.lat];

  // Convert to geohash (with specified precision) to allow for easy testing.
  const geohash = Geohash.fromLonLat(lonLat, 9);
  const extent  = Geohash.getExtent(geohash);
  const polygon = Polygon.fromExtent(extent);

  polygon.transform(projections.wgs84, projections.map);

  return {
    geohash,

    landmark:   address.landmark,
    zone:       address.zone,
    street:     address.street,
    streetNo:   address.streetNo,
    buildingNo: address.buildingNo,
    blockNo:    address.blockNo,

    source: address.source,

    coords: fromLonLat(lonLat, projections.map),
    extent: polygon.getCoordinates(),
  };
}

export default {
  components: {
    Map,
  },

  props: {
    center: {
      type: Object,
      default: null,
    },

    highlight: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      controls: Object.freeze([
        new ButtonControl({
          callback: this.loadAddresses,
          content: "&#x1f5d8;",
          classes: "load-addresses",
        })
      ]),

      highlightCoords: null,

      addresses: {},
    };
  },

  computed: {
    addressEntries() {
      return Object.values(this.addresses);
    },
  },

  watch: {
    center(position) {
      if (position) {
        this.zoomLevel    = defaultZoom;
        this.centerCoords = fromLonLat([position.lng, position.lat], projections.map);
      }
    },

    highlight(position) {
      if (position) {
        this.highlightCoords = fromLonLat([position.lng, position.lat], projections.map);
      } else {
        this.highlightCoords = null;
      }
    },
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Public ***** ***** ***** ***** ***** \*/
    addAddress(address) {
      const mapped = mapAddress(address);

      this.$set(this.addresses, mapped.geohash, mapped);
    },

    removeAddress(address) {
      if (address && address.geohash) {
        this.$delete(this.addresses, address.geohash);
      }
    },

    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    mapMounted() {
      const formHeight = jQuery("#address-form").height();
      const mapHeight  = Math.max(formHeight, 700);

      this.$refs.map.resize(mapHeight);
    },

    mapClicked(event) {
      const lonLat   = toLonLat(event.coordinate, projections.map);
      const features = event.map.getFeaturesAtPixel(event.pixel);

      const data = {
        lat: lonLat[1],
        lng: lonLat[0],
      }

      if (features && features.length > 0) {
        data.geohash = features[0].getId();
      };

      this.$emit("click", data);
    },

    mapMoved(event) {
      const view = event.map.getView();
      const size = event.map.getSize();

      this.visibleExtent = view.calculateExtent(size);
    },

    async loadAddresses() {
      const extentWkt = wkt.writeGeometry(Polygon.fromExtent(this.visibleExtent));
      const addresses = await api.addresses.list(extentWkt);

      this.replaceAddresses(addresses);
    },

    replaceAddresses(addresses) {
      const mapped = addresses.map(mapAddress);

      this.addresses = lodash.keyBy(mapped, "geohash");
    },
  },
};
</script>

<style lang="scss">
#addresses-map {
  .load-addresses {
    top: 4.5em;
    right: .5em;
  }
}
</style>
