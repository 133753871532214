<template>
  <b-form
    id="address-form"
    autocomplete="off"
    novalidate
    v-on:submit.prevent="submit"
    v-on:reset.prevent="reset"
  >
    <div class="card card-custom card-stretch bg-light gutter-b">
      <div class="card-header">
        <h4 class="card-title">Adresă</h4>
      </div>

      <div class="card-body">
        <b-form-group label="Cartier" label-for="address-zone" label-cols-xl="3">
          <b-form-input
            id="address-zone"
            aria-describedby="address-zone-feedback"
            v-model="$v.address.zone.$model"
            v-bind:state="state.address.zone"
            v-bind:trim="true"
            v-on:input="resetEmptyAddressFields"
          />

          <b-form-invalid-feedback id="address-zone-feedback">
            <span v-if="!$v.address.zone.required">Cartierul este obligatoriu.</span>
            <span v-if="!$v.address.zone.characters">Cartierul conține caractere nepermise.</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Stradă" label-for="address-street" label-cols-xl="3">
          <b-form-input
            id="address-street"
            aria-describedby="address-street-feedback"
            v-model="$v.address.street.$model"
            v-bind:state="state.address.street"
            v-bind:trim="true"
            v-on:input="resetEmptyAddressFields"
          />

          <b-form-invalid-feedback id="address-street-feedback">
            <span v-if="!$v.address.street.required">Strada este obligatorie.</span>
            <span v-if="!$v.address.street.characters">Strada conține caractere nepermise.</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-form-group label="Număr" label-for="address-street-no" label-cols-xl="3">
          <b-form-input
            id="address-street-no"
            aria-describedby="address-street-no-feedback"
            v-model="$v.address.streetNo.$model"
            v-bind:state="state.address.streetNo"
            v-bind:trim="true"
            v-on:input="resetEmptyAddressFields"
          />

          <b-form-invalid-feedback id="address-street-no-feedback">
            <span v-if="!$v.address.streetNo.required">Numărul este obligatoriu.</span>
            <span v-if="!$v.address.streetNo.characters">Numărul conține caractere nepermise.</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row class="row-inline">
          <b-col id="building-no-row" cols="6">
            <b-form-group label="Bloc" label-for="address-building-no" label-cols="5" label-cols-xl="6">
              <b-form-input
                id="address-building-no"
                aria-describedby="address-building-no-feedback"
                v-model="$v.address.buildingNo.$model"
                v-bind:state="state.address.buildingNo"
                v-bind:trim="true"
                v-on:input="resetEmptyAddressFields"
              />

              <b-form-invalid-feedback id="address-building-no-feedback">
                <span v-if="!$v.address.buildingNo.required">Blocul este obligatoriu.</span>
                <span v-if="!$v.address.buildingNo.characters">Blocul conține caractere nepermise.</span>
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>

          <b-col id="block-no-row" cols="6">
            <b-form-group label="Scară" label-for="address-block-no" label-cols="5">
              <b-form-input
                id="address-block-no"
                aria-describedby="address-block-no-feedback"
                v-model="$v.address.blockNo.$model"
                v-bind:state="state.address.blockNo"
                v-bind:trim="true"
                v-on:input="resetEmptyAddressFields"
              />

              <b-form-invalid-feedback id="address-block-no-feedback">
                Scara conține caractere nepermise.
              </b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-row>

        <b-form-group label="Punct de interes" label-for="address-landmark" label-cols-xl="3">
          <b-form-input
            id="address-landmark"
            aria-describedby="address-landmark-feedback"
            v-model="$v.address.landmark.$model"
            v-bind:state="state.address.landmark"
            v-bind:trim="true"
            v-on:input="resetEmptyAddressFields"
          />

          <b-form-invalid-feedback id="address-landmark-feedback">
            <span v-if="!$v.address.landmark.required">Punctul de interes este obligatoriu.</span>
          </b-form-invalid-feedback>
        </b-form-group>

        <b-row>
          <b-col cols-cols="10" offset-cols="2">
            <b-form-invalid-feedback v-if="addressRequired" id="address-invalid-feedback">
              Adresa este incompletă.
            </b-form-invalid-feedback>

            <b-form-invalid-feedback v-if="error" id="error-invalid-feedback">
              {{ error }}
            </b-form-invalid-feedback>
          </b-col>
        </b-row>
      </div>

      <div class="card-footer">
        <b-button class="float-right" type="submit" variant="primary">Salvează</b-button>
        <b-button type="reset" variant="danger">Șterge</b-button>
      </div>
    </div>
  </b-form>
</template>

<script>
import jQuery from "jquery";
import lodash from "lodash";

import { validationMixin } from "vuelidate";
import { helpers, required, requiredIf } from "vuelidate/lib/validators";

import api from "@/core/services/api";
import { isNullOrEmpty, formatAddress } from "@/core/utils";

const textNum = helpers.regex("textNum", /^[a-zA-Z0-9 ]+$/);

const streetChars     = helpers.regex("streetChars", /^[a-zA-Z0-9.`'\- ]+$/);
const buildingNoChars = helpers.regex("buildingNoChars", /^[a-zA-Z0-9.`'\- ]+$/); // TODO: Remove space & dot once we have proper POI support in NanoTerra.

function mapValidationState(state) {
  if (state.$dirty) {
    if (state.$error) {
      return false;
    }

    if (!isNullOrEmpty(state.$model)) {
      return true;
    }
  }

  return null;
}

function zoneRequired(address) {
  const hasStreet     = !isNullOrEmpty(address.street);
  const hasStreetNo   = !isNullOrEmpty(address.streetNo);
  const hasBuildingNo = !isNullOrEmpty(address.buildingNo);
  const hasLandmark   = !isNullOrEmpty(address.landmark);
  const hasNotes      = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasStreet) {
    if (hasStreetNo || hasBuildingNo) {
      return false;
    }
  }

  return hasBuildingNo;
}

function streetRequired(address) {
  const hasZone       = !isNullOrEmpty(address.zone);
  const hasStreetNo   = !isNullOrEmpty(address.streetNo);
  const hasBuildingNo = !isNullOrEmpty(address.buildingNo);
  const hasLandmark   = !isNullOrEmpty(address.landmark);
  const hasNotes      = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasZone && hasBuildingNo) {
    return false;
  }

  return hasStreetNo || hasBuildingNo;
}

function streetNoRequired(address) {
  const hasZone       = !isNullOrEmpty(address.zone);
  const hasStreet     = !isNullOrEmpty(address.street);
  const hasBuildingNo = !isNullOrEmpty(address.buildingNo);
  const hasLandmark   = !isNullOrEmpty(address.landmark);
  const hasNotes      = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasBuildingNo) {
    if (hasZone || hasStreet) {
      return false;
    }
  }

  return hasStreet;
}

function buildingNoRequired(address) {
  const hasZone     = !isNullOrEmpty(address.zone);
  const hasStreet   = !isNullOrEmpty(address.street);
  const hasStreetNo = !isNullOrEmpty(address.streetNo);
  const hasLandmark = !isNullOrEmpty(address.landmark);
  const hasNotes    = !isNullOrEmpty(this.notes);

  if (hasLandmark || hasNotes) {
    return false;
  }

  if (hasStreet && hasStreetNo) {
    return false;
  }

  return hasZone || hasStreet;
}

function landmarkRequired(address) {
  return false; // TODO
}

function autocompleteDebounce(handler) {
  return lodash.debounce((input, callback) => handler(input).then(callback), 100);
}

function autocompleteHandle(results, element) {
  if (element.is(":focus")) {
    return results;
  } else {
    return [];
  }
}

function autocompleteHighlight(haystack, needle) {
  const startIndex = haystack.toLowerCase().indexOf(needle.toLowerCase());

  if (startIndex === -1) return haystack;

  const endIndex = startIndex + needle.length;

  const before = haystack.slice(0, startIndex);
  const match  = haystack.slice(startIndex, endIndex);
  const after  = haystack.slice(endIndex);

  return `${before}<b>${match}</b>${after}`;
}

export default {
  mixins: [ validationMixin ],

  props: {
    value: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      error: null,

      address: {
        geohash: null,

        zone: "",
        street: "",
        streetNo: "",
        buildingNo: "",
        blockNo: "",
        landmark: "",
      },
    };
  },

  validations: {
    address: {
      zone: {
        characters: textNum,
        required: requiredIf(zoneRequired),
      },

      street: {
        characters: streetChars,
        required: requiredIf(streetRequired),
      },

      streetNo: {
        characters: textNum,
        required: requiredIf(streetNoRequired),
      },

      buildingNo: {
        characters: buildingNoChars,
        required: requiredIf(buildingNoRequired),
      },

      blockNo: {
        characters: textNum,
      },

      landmark: {
        required: requiredIf(landmarkRequired),
      },
    },
  },

  computed: {
    formFilled() {
      return this.$v.$dirty;
    },

    // Separate handling of empty fields is necessary because all validation
    // is done using requiredIf checking the presence of another field.
    // There is no address field that is required in all situations.
    addressEmpty() {
      const requiredAddressFields = [
        this.address.zone,
        this.address.street,
        this.address.streetNo,
        this.address.buildingNo,
        this.address.landmark
      ];

      return lodash.every(requiredAddressFields, isNullOrEmpty);
    },

    addressRequired() {
      return this.formFilled && this.addressEmpty;
    },

    state() {
      return {
        address: {
          zone:       mapValidationState(this.$v.address.zone),
          street:     mapValidationState(this.$v.address.street),
          streetNo:   mapValidationState(this.$v.address.streetNo),
          buildingNo: mapValidationState(this.$v.address.buildingNo),
          blockNo:    mapValidationState(this.$v.address.blockNo),
          landmark:   mapValidationState(this.$v.address.landmark),
        },
      };
    },
  },

  watch: {
    value(newValue) {
      this.address = { ...newValue };
      this.$v.$reset();
    },
  },

  mounted() {
    jQuery("#address-zone").autoComplete({
      resolver: "custom",
      noResultsText: "Niciun rezultat",

      formatResult: result => {
        const highlighted = autocompleteHighlight(result, jQuery("#address-zone").val());

        return {
          text: result,
          html: highlighted,
        };
      },

      events: {
        search: autocompleteDebounce(input => api.addresses.searchZone(input)),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-zone").on("autocomplete.select", (_, zone) => {
      this.address.zone = zone;
    });



    jQuery("#address-street").autoComplete({
      resolver: "custom",
      noResultsText: "Niciun rezultat",

      formatResult: result => {
        const highlighted = autocompleteHighlight(result, jQuery("#address-street").val());

        return {
          text: result,
          html: highlighted,
        };
      },

      events: {
        search: autocompleteDebounce(input => api.addresses.searchStreet(input)),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-street").on("autocomplete.select", (_, street) => {
      this.address.street = street;
    });



    jQuery("#address-building-no").autoComplete({
      resolver: "custom",
      minLength: 2,
      noResultsText: "Niciun rezultat",

      formatResult: address => {
        const formatted   = formatAddress(address, ["buildingNo"]);
        const highlighted = autocompleteHighlight(formatted, jQuery("#address-building-no").val());

        return {
          text: address.buildingNo,
          html: highlighted,
        };
      },

      events: {
        search: autocompleteDebounce(input => api.addresses.searchBuildingNo(input)),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-building-no").on("autocomplete.select", (event, address) => {
      this.address.buildingNo = address.buildingNo;

      if (!isNullOrEmpty(address.zone)) {
        this.address.zone = address.zone;
      }

      if (!isNullOrEmpty(address.street)) {
        this.address.street = address.street;
      }
    });



    jQuery("#address-landmark").autoComplete({
      resolver: "custom",
      minLength: 2,
      noResultsText: "Niciun rezultat",

      formatResult: result => {
        const highlighted = autocompleteHighlight(result, jQuery("#address-landmark").val());

        return {
          text: result,
          html: highlighted,
        };
      },

      events: {
        search: autocompleteDebounce(input => api.addresses.searchLandmark(input)),
        searchPost: autocompleteHandle,
      },
    });

    jQuery("#address-landmark").on("autocomplete.select", (_, landmark) => {
      this.address.landmark = landmark;
    });



    jQuery("#address-form input").keydown(event => {
      const target    = jQuery(event.target);
      const focusable = jQuery("#address-form")
        .find("input, select, textarea, [contenteditable=true]")
        .filter(":visible");

      // Index of the next/previous item (depending on [Shift] keypress).
      const next = focusable.index(target) + (event.shiftKey ? -1 : 1);

      // [Enter]
      if (event.which === 13) {
        // Skip enter-as-tab handling for textarea or content-editable elements.
        if (target.is("textarea, [contenteditable=true]")) return;

        if (next >= 0 && next < focusable.length) {
          // Focus the next/previous element.
          focusable.eq(next).focus();
        }

        event.stopPropagation();
        event.preventDefault();
      }

      // [Tab]
      if (event.which === 9) {
        // Stop tabbing at the first & last elements.
        if (next < 0 || next >= focusable.length) {
          event.stopPropagation();
          event.preventDefault();
        }
      }
    });
  },

  methods: {
    /*\ ***** ***** ***** ***** ***** Private ***** ***** ***** ***** ***** \*/
    resetEmptyAddressFields() {
      if (isNullOrEmpty(this.address.zone)) {
        this.$v.address.zone.$reset();
      }

      if (isNullOrEmpty(this.address.street)) {
        this.$v.address.street.$reset();
      }

      if (isNullOrEmpty(this.address.streetNo)) {
        this.$v.address.streetNo.$reset();
      }

      if (isNullOrEmpty(this.address.buildingNo)) {
        this.$v.address.buildingNo.$reset();
      }

      if (isNullOrEmpty(this.address.landmark)) {
        this.$v.address.landmark.$reset();
      }
    },

    async submit() {
      this.$v.$touch();

      if (this.$v.address.$anyError) return;

      const address = { ...this.address };

      this.$emit("input", address);
    },

    reset() {
      this.$emit("delete");
    },
  },
};
</script>

<style lang="scss">
@import "~bootstrap/scss/_mixins.scss";

#address-form {
  .card-header {
    min-height: 50px;
  }

  .card-body {
    padding: 1rem 2.25rem;

    .form-group {
      margin-bottom: 0.5rem;

      @include media-breakpoint-up(xl) {
        margin-bottom: 0;
      }

      &.form-row {
        margin-bottom: 0.5rem;

        @include media-breakpoint-down(lg) {
          label {
            padding-bottom: 0;
          }

          &:first-child {
            label {
              padding-top: 0;
            }
          }
        }

        @include media-breakpoint-up(xl) {
          margin-bottom: 1rem;
        }
      }

      .form-row {
        legend {
          padding: 0;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
      }

      .row-inline {
        @include media-breakpoint-down(lg) {
          margin-top: 1.25rem;

          .form-group {
            &.form-row {
              label {
                padding-top: calc(0.65rem + 1px);
              }
            }
          }
        }
      }
    }
  }

  .card-footer {
    padding: 1rem 2.25rem;
  }

  .bootstrap-autocomplete {
    padding: 0;
    width: auto !important;
    border: $input-border-width solid $input-border-color;

    .dropdown-item {
      display: block;
    }
  }
}

#address-invalid-feedback, #error-invalid-feedback {
  display: block;
  margin-bottom: 1rem;
}

#building-no-row {
  padding-right: 0.25rem;
}

#building-no-row, #block-no-row {
  label {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  @include media-breakpoint-down(xl) {
    input.is-valid {
      padding-right: 0;
      background-image: none;
    }
  }
}
</style>
